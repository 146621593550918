   header.header {
       background-color: white;
   }

   div.header-topbar-wrapper {
       position: relative;
   }

   header.header div.header-navigation {
       background-color: #ffd452;
   }

   header.header div.header-navigation a {
       color: black;
   }

   div.header-wrapper>ul {
       width: 100%;
       display: table;
       margin-bottom: 0;
   }

   div.header-wrapper>ul>li {
       display: table-cell;
       width: auto;
       text-align: center;
       line-height: 3em;
       font-size: 1.5em;
       position: relative;
   }

   div.header-wrapper>ul>li:hover {
       background-color: #FFC001;
   }

   div.header-wrapper>ul>li a.parent+ul.children {
       display: none;
       position: absolute;
       background-color: #ffd452;
       padding: 0;
       width: 100%;
   }

   div.header-wrapper>ul>li:hover a.parent+ul.children {
       animation: opctShow 0.4s 1;
       display: block;
       z-index: 50;
   }

   div.header-wrapper>ul>li a.parent+ul.children>li {
       white-space: nowrap;
       padding: 0 10px 0 10px;
   }

   div.header-wrapper>ul>li a.parent+ul.children>li:hover {
       background-color: #FFC001;
   }

   div.header-wrapper a {
       text-decoration: none;
   }

   div.header-wrapper>ul>li:after,
   ul#header-providers-wrapper>li:after {
       content: "";
       background-repeat: no-repeat;

       background-size: contain;
      background-position-y: 50%;
      background-position-x: 50%;

       opacity: 0.3;
       top: 0;
       left: 0;
       bottom: 0;
       right: 0;
       position: absolute;
       z-index: 1;
       pointer-events: none;
   }

   ul#header-providers-wrapper li {
       position: relative;
   }

   .mod-bkgd {
    /*color: transparent;*/
   }

   .mod-bkgd-PT:after {
       background-image: url("/images/logos/PT.png");
   }

   .mod-bkgd-MG:after {
       background-image: url("/images/logos/MG.png");
   }

   .mod-bkgd-PNG:after {
       background-image: url("/images/logos/PNG.png");
   }

   .mod-bkgd-MW:after {
       background-image: url("/images/logos/MW.png");
   }

   .mod-bkgd-RTG:after-HD {
       background-image: url("/images/logos/RTG-HD.png");
   }

   .mod-bkgd-Quickspin:after {
       background-image: url("/images/logos/Quickspin.png");
   }

   .mod-bkgd-NYX:after {
       background-image: url("/images/logos/NYX.png");
   }

   .mod-bkgd-RTG:after {
       background-image: url("/images/logos/RTG.png");
   }

   .mod-bkgd-TOMHORN:after {
       background-image: url("/images/logos/TOMHORN.png");
   }

   .mod-bkgd-SPINOMENAL:after {
       background-image: url("/images/logos/SPINOMENAL.png");
   }

   .mod-bkgd-NT:after {
       background-image: url("/images/logos/NT.png");
   }

   .mod-bkgd-Pragmatic:after {
       background-image: url("/images/logos/Pragmatic.png");
   }

   .mod-bkgd-GM:after {
       background-image: url("/images/logos/GM.png");
   }

   .mod-bkgd-GameArt:after {
       background-image: url("/images/logos/GameArt.png");
   }

   .mod-bkgd-QT:after {
       background-image: url("/images/logos/QT.png");
   }

   .mod-bkgd-Habanero:after {
       background-image: url("/images/logos/Habanero.png");
   }

   .mod-bkgd-GG:after {
       background-image: url("/images/logos/GG.png");
   }

   .mod-bkgd-Betsoft:after {
       background-image: url("/images/logos/Betsoft.png");
   }


   @keyframes opctShow {
       from {
           opacity: 0;
       }
       to {
           opacity: 1;
       }
   }